<template>
  <div class="index">
    <navigation msg="岗位发布"></navigation>
    <div class="title">
      <div class="left">试试电脑端快速发布</div>
      <div class="right"><van-icon name="arrow" /></div>
    </div>
    <div class="step">
      <div class="step_1">3</div>
      <div class="step_xian"></div>
      <div class="step_2">4</div>
    </div>
    <div class="step_text">
      <div class="step_text_1">面试信息情况及招聘服务说明</div>
      <div class="step_text_2">岗位薪资待遇</div>
    </div>
    <div class="item2">
      <div class="label">
        <span>*</span>是否委托萝卜猎手进行初筛
        <i @click="show4 = true">查看样例报告</i>
      </div>
      <div class="name" @click="show1 = true">
        <div :style="is_lbls_interview ? 'color: #000' : ''">
          {{ is_lbls_interview || "请选择是否委托萝卜猎手进行初筛" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show1" round position="bottom">
      <van-picker
        @confirm="onConfirm1"
        title="是否委托萝卜猎手进行初筛"
        show-toolbar
        value-key="label"
        :columns="columns1"
        @cancel="show1 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>面试轮次</div>
      <div class="name">
        <van-stepper
          @plus="numChange('add')"
          @minus="numChange('minus')"
          v-model="form.interview_rounds"
        />
      </div>
    </div>
    <div class="item1 item2">
      <div class="label"><span>*</span>面试职位</div>
      <div v-for="(item, index) in positionList" :key="index">
        <div class="name">
          <van-field v-model="item.job" placeholder="请输入面试官职位" />
        </div>
        <div class="name">
          <van-radio-group v-model="item.type" direction="horizontal">
            <van-radio name="1" checked-color="#EB4E36">电话面试</van-radio>
            <van-radio name="2" checked-color="#EB4E36">现场面试</van-radio>
            <van-radio name="3" checked-color="#EB4E36">视频面试</van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>

    <div class="item2">
      <div class="label">招聘服务说明</div>
      <div class="name">
        <van-checkbox
          :disabled="poststatus == 'edit'"
          v-model="checked"
          checked-color="#EB4E36"
          >人才分析报告</van-checkbox
        >
        <van-checkbox
          :disabled="poststatus == 'edit'"
          v-model="checked2"
          checked-color="#EB4E36"
          >背景调查报告</van-checkbox
        >
        <div style="width: 100px"></div>
      </div>
    </div>
    <div class="item2">
      <div class="label"><span>*</span>招聘岗位推荐时常建议</div>
      <div class="name" @click="show2 = true">
        <div :style="expire ? 'color: #000' : ''">
          {{ expire || "请选择招聘岗位推荐时常建议" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show2" round position="bottom">
      <van-picker
        @confirm="onConfirm2"
        title="招聘岗位推荐时常建议"
        show-toolbar
        value-key="label"
        :columns="columns2"
        @cancel="show2 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>目标岗位招聘完成形式</div>
      <div class="name" @click="show3 = true">
        <div :style="service_content ? 'color: #000' : ''">
          {{ service_content || "请选择目标岗位招聘完成形式" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show3" round position="bottom">
      <van-picker
        @confirm="onConfirm3"
        title="请选择目标岗位招聘完成形式"
        show-toolbar
        value-key="label"
        :columns="columns3"
        @cancel="show3 = false"
      />
    </van-popup>
    <div class="item2" v-if="form.service_content == 2">
      <div class="label"><span>*</span>请选择入职过保期限</div>
      <div class="name">
        <van-radio-group v-model="form.guarantee_time" direction="horizontal">
          <van-radio name="1" checked-color="#EB4E36">7天</van-radio>
          <van-radio name="2" checked-color="#EB4E36">14天</van-radio>
          <van-radio name="3" checked-color="#EB4E36">30天</van-radio>
        </van-radio-group>
      </div>
    </div>
    <div style="height: 2rem"></div>
    <div class="footer">
      <div class="btn1" @click="save">保存</div>
      <div class="btn2" @click="back">上一步</div>
      <div class="btn3" @click="toPage">下一步</div>
    </div>
    <van-dialog v-model="show4">
      <img
        @click="Preview"
        style="width: 100%"
        src="../../assets/report_example.png"
      />
    </van-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      checked: true,
      checked2: true,
      positionList: [
        {
          job: "",
          type: "2",
        },
      ],
      value: "",
      value2: "",
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      columns1: [
        {
          label: "委托",
          value: "1",
        },
        {
          label: "不委托",
          value: "2",
        },
      ],
      columns2: [
        {
          label: "30天",
          value: "1",
        },
        {
          label: "60天",
          value: "2",
        },
      ],
      columns3: [
        {
          label: "入职成功",
          value: "1",
        },
        {
          label: "入职过保",
          value: "2",
        },
      ],
      img: require("../../assets/report_example.png"),
      expire: "60天",
      is_lbls_interview: "不委托",
      service_content: "入职成功",
      form: {
        is_lbls_interview: 2, // 是否萝卜猎手首轮面试
        interview_rounds: 1, //面试轮次
        interview_man: "", //面试官职位
        expire: 2, //悬赏有效期
        post_suitable: 1, //是否需要岗位适配度报告1-需要 0-不需要
        survey: 0, //是否需要问卷调查1-需要 0-不需要
        backgroud_report: 1, //是否需要背调报告 1-需要 0-不需要
        service_content: 1, //服务内容 1-入职成功 2-入职过保
        guarantee_time: 1, //保障时间(天数): 1-7天 2-10天 3-90天
      },
    };
  },
  mounted() {
    console.log(this.forms);
    if (Object.keys(this.forms).length != 0) {
      Object.keys(this.forms).forEach((key) => {
        this.form[key] = this.forms[key];
      });
      this.form.interview_type = this.forms.interview_type;
      if (this.form.interview_rounds) {
        let arr = [];
        let arr1 = [];
        let arr2 = [];
        if (this.form.interview_man) {
          arr = this.form.interview_man.split(",");
        }
        if (this.form.interview_type) {
          arr1 = this.form.interview_type.split(",");
        }
        this.checked = this.form.post_suitable == 0 ? false : true;
        this.checked2 = this.form.backgroud_report == 0 ? false : true;
        this.expire = this.form.expire == 1 ? "30天" : "60天";
        this.is_lbls_interview =
          this.form.is_lbls_interview == 1 ? "委托" : "不委托";
        this.service_content =
          this.form.service_content == 1 ? "入职成功" : "入职过保";
        for (let i = 0; i < this.form.interview_rounds; i++) {
          let obj1 = {
            job: arr[i] ? arr[i] : "",
            type: arr1[i] ? arr1[i] : "2",
          };
          arr2.push(obj1);
        }
        this.positionList = arr2;
      }
    }
  },
  computed: {
    // 引入vuex中存储的数据
    ...mapState({
      forms: (state) => state.form,
      poststatus: (state) => state.poststatus,
    }),
  },
  methods: {
    save() {
      this.form.interview_man = arr.join(",");
      this.form.interview_type = arr1.join(",");
      this.form = Object.assign({}, this.forms, this.form);
      this.$store.commit("SET_POST", this.form);
      this.$toast("保存成功");
    },
    Preview() {
      ImagePreview([
        "http://luoboentev1.udao.sh.cn/static/img/report_example.11034b7.png",
      ]);
    },
    onConfirm1(value, index) {
      if (this.poststatus == "edit") {
        this.show1 = false;
        return;
      }
      this.form.is_lbls_interview = value.value;
      this.is_lbls_interview = value.label;
      this.show1 = false;
    },
    onConfirm2(value, index) {
      this.form.expire = value.value;
      this.expire = value.label;
      this.show2 = false;
    },
    onConfirm3(value, index) {
      if (this.poststatus == "edit") {
        this.show3 = false;
        return;
      }
      this.form.service_content = value.value;
      this.service_content = value.label;
      this.show3 = false;
    },

    numChange(type) {
      this.form.interview_rounds = Number(this.form.interview_rounds);
      if (type == "add") {
        this.form.interview_rounds += 1;
        let obj = {
          job: "",
          type: "2",
        };
        this.positionList.push(obj);
      } else {
        if (this.form.is_lbls_interview == 1 && this.positionList.length == 2) {
          // console.log('禁止减少')
        } else if (this.form.interview_rounds == 1) {
        } else {
          this.form.interview_rounds -= 1;
          this.positionList.splice(this.positionList.length - 1, 1);
        }
      }
    },
    back() {
      this.$router.go(-1);
    },
    toPage() {
      let arr = [];
      let arr1 = [];
      let isTrue = true;
      this.positionList.forEach((item, index) => {
        if (!item.job) {
          this.$message.warning("请完善面试官职位再进行下一步！");
          isTrue = false;
        } else {
          arr.push(item.job);
        }
        if (!item.type) {
          this.$message.warning("请选择面试形式再进行下一步！");
          isTrue = false;
          return;
        } else {
          arr1.push(item.type);
        }
      });
      this.form.post_suitable = this.checked ? 1 : 0;
      this.form.backgroud_report = this.checked2 ? 1 : 0;
      if (isTrue) {
        this.form.interview_man = arr.join(",");
        this.form.interview_type = arr1.join(",");
        this.form = Object.assign({}, this.forms, this.form);
        this.$store.commit("SET_POST", this.form);
        this.$router.push({
          path: "/step4",
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.index {
  background-color: #fff;
}
.index /deep/ .van-picker-column__item--selected {
  color: red !important;
}
.index /deep/ .van-picker__confirm {
  color: red !important;
}
.van-cell {
  padding: 10px 0px;
}
.title {
  display: flex;
  justify-content: space-between;
  height: 0.88rem;
  padding: 0 0.28rem;
  line-height: 0.88rem;
  background: #fff0e5;
  .left,
  .right {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff3b24;
  }
}
.step {
  margin-top: 0.32rem;
  padding: 0 1.12rem;
  display: flex;
  align-items: center;
  .step_1,
  .step_2 {
    width: 0.42rem;
    height: 0.42rem;
    border-radius: 50%;
    background: #ff3b24;
    text-align: center;
    line-height: 0.42rem;
  }
  .step_xian {
    flex: 1;
    height: 8px;
    background: linear-gradient(270deg, #ffc4bd 0%, #ff3b24 100%);
  }
  .step_1 {
    transform: translateX(0.02rem);
    color: #fff;
  }
  .step_2 {
    transform: translateX(-0.02rem);
    color: #fff;
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    background: #ff3b24;
    opacity: 0.3;
  }
}
.step_text {
  margin-bottom: 0.8rem;
  margin-top: 0.21rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.35rem;
  .step_text_1 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .step_text_2 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #acb5c8;
  }
}
.index /deep/ input::placeholder {
  font-size: 0.3rem;
}
//输入框
.item1 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
  }
  .name {
    display: flex;
    align-items: center;

    height: 1.12rem;
  }
}
//选择框
.item2 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
    i {
      font-style: none;
      color: #6277da;
    }
  }
  .name {
    height: 1.12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 0.3rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #c8c8c8;
    }
  }
  .solt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    .btn1 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #fff0e5;
      border-radius: 0.03rem;
      border: 0.02rem solid #ff3b24;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff3b24;
    }
    .btn2 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #f1f0ef;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #6d6b6b;
      border-radius: 0.03rem;
    }
  }
}
.footer {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.29rem;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 1.56rem;
  border-top: 0.02rem solid #f2f2f2;
  background: #ffffff;
  .btn1 {
    text-align: center;
    line-height: 0.95rem;
    width: 1.97rem;
    height: 0.95rem;
    background: #ffffff;
    border-radius: 0.1rem;
    border: 0.03rem solid #c3c3c3;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #989898;
  }
  .btn2 {
    text-align: center;
    line-height: 0.95rem;
    width: 2.36rem;
    height: 0.95rem;
    background: #ffffff;
    border-radius: 0.1rem;
    border: 0.03rem solid #c3c3c3;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #989898;
  }
  .btn3 {
    text-align: center;
    line-height: 0.95rem;
    width: 2.36rem;
    height: 0.95rem;
    background: #ff3b24;
    border-radius: 0.1rem;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>